export function validateSelection(name = null) {
    if(name) {
        const inputs = document.querySelectorAll(name);
        for(let i=0; i<inputs.length; i++) {
            if (inputs[i].checked) {
                return false;
            }
        }

     return true
    }
    return false
}

export function yearsValidation(curVal, from, to) {
    if (curVal < from || curVal>to) {
        return true
    }
    return false
}